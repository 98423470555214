import Footer from "../components/Footer";
import Header from "../components/Header";
import "../user/profile.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
//import MoveUpIcon from "@mui/icons-material/MoveUp";
import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";
import PriceChangeOutlinedIcon from "@mui/icons-material/PriceChangeOutlined";
//import LocalAtmOutlinedIcon from "@mui/icons-material/LocalAtmOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import QueryStatsOutlinedIcon from "@mui/icons-material/QueryStatsOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import LockPersonOutlinedIcon from "@mui/icons-material/LockPersonOutlined";
import LockResetIcon from "@mui/icons-material/LockReset";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import LockOpenIcon from "@mui/icons-material/LockOpen";
//import SavingsOutlinedIcon from "@mui/icons-material/SavingsOutlined";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import AnimatedPage from "../components/AnimatedPage";

function Profile() {
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    const [setting, setSetting] = useState(JSON.parse(localStorage.getItem("setting")));
    const navigate = useNavigate();
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));
        axios.get(`${process.env.REACT_APP_API_URL}/setting/get`, {}).then((res) => {
            if (setting != res.data.data[0]) {
                setSetting(res.data.data[0]);
                localStorage.setItem("setting", JSON.stringify(res.data.data[0]));
            }
        });
    }, []);
    return (
        <>
            <Header profile={profile} />
            {profile && (
                <AnimatedPage>
                    <div className="wallet-item" style={{ border: "0", margin: "10px 0 0", background: "transparent", width: "100%" }}>
                        <div class="wallet-amount">
                            {profile ? (
                                <div className="personal-bar">
                                    <div className="avatar">
                                        <img alt="" src={require("../../img/profile-picture.jpg")} />
                                    </div>
                                    <div className="personal">
                                        {profile ? <h2>{profile.username}</h2> : <h2>Đăng nhập/Đăng ký</h2>}
                                        {profile ? <p>{profile.level === 0 ? "VIP 1" : "VIP " + profile.level}</p> : <p>Tham gia ngay</p>}
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <p>Chào mừng đến với YO68</p>
                                    <b onClick={() => navigate("/login")}>Tham gia ngay</b>
                                </>
                            )}
                        </div>
                        <div class="wallet-func">
                            <div class="wallet-func-item partner">
                                <a>
                                    <img src={require("../../static/home_top_vip.png")} />
                                    <h3>VIP</h3>
                                </a>
                            </div>
                            <div class="wallet-func-item deposit">
                                <Link to="/recharge">
                                    <img src={require("../../static/home_top_deposit.png")} />
                                    <h3>NẠP</h3>
                                </Link>
                            </div>
                            <div class="wallet-func-item withdrawal">
                                <Link to="/withdraw">
                                    <img src={require("../../static/home_top_withdraw.png")} />
                                    <h3>RÚT</h3>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="vip-card">
                        <div>
                            <img alt="" src={require("../../img/invitation_bg.png")} style={{ height: "50px" }} />
                        </div>
                        <div>
                            <p style={{ textAlign: "left" }}>
                                Mời bạn bè tham gia <br />
                                nhận hoa hồng và các ưu đãi
                            </p>
                        </div>
                        <div>
                            <button className="badge green" onClick={() => navigate("/invite")}>
                                Khám phá ngay
                            </button>
                        </div>
                    </div>
                    <div className="account">
                        <div className="account__menu">
                            {/*<Link to="/detail" className="account__menu-item">
                                    <span>
                                        <PermIdentityIcon sx={{ fontSize: "25px" }} />
                                        Chi tiết tài khoản
                                    </span>
                                    <KeyboardArrowRightOutlinedIcon />
                                </Link>*/}
                            <Link to="/transfergame" className="account__menu-item">
                                <span>
                                    <img src={require("../../img/edzh@3x.341e695f.png")} />
                                    Chuyển tiền vào sảnh
                                </span>
                                <KeyboardArrowRightOutlinedIcon />
                            </Link>
                            <Link to="/historycasino" className="account__menu-item">
                                <span>
                                    <img src={require("../../img/shuying.777101d3.png")} />
                                    Lịch sử trò chơi sảnh
                                </span>
                                <KeyboardArrowRightOutlinedIcon />
                            </Link>
                            <Link to="/history" className="account__menu-item">
                                <span>
                                    <img src={require("../../img/weijie.c69057ed.png")} />
                                    Lịch sử chuyển tiền sảnh
                                </span>
                                <KeyboardArrowRightOutlinedIcon />
                            </Link>
                            <Link to="/historyplay" className="account__menu-item">
                                <span>
                                    <img src={require("../../img/qtxzjl@3x.f00fd092.png")} />
                                    Lịch sử trò chơi
                                </span>
                                <KeyboardArrowRightOutlinedIcon />
                            </Link>
                            {/*<Link to="/money" className="account__menu-item">
                                    <span>
                                        <SavingsOutlinedIcon sx={{ fontSize: "25px" }} />
                                        Két tiết kiệm
                                    </span>
                                    <KeyboardArrowRightOutlinedIcon />
                                </Link>*/}
                            <Link to="/recharge_record" className="account__menu-item">
                                <span>
                                    <img src={require("../../img/zjmx@3x.0104e04c.png")} />
                                    Lịch sử nạp
                                </span>
                                <KeyboardArrowRightOutlinedIcon />
                            </Link>
                            <Link to="/withdraw_record" className="account__menu-item">
                                <span>
                                    <img src={require("../../img/xzjl@3x.70805dba.png")} />
                                    Lịch sử rút
                                </span>
                                <KeyboardArrowRightOutlinedIcon />
                            </Link>
                            <Link to="/addbank" className="account__menu-item">
                                <span>
                                    <img src={require("../../img/yhk@3x.f1f03a6f.png")} />
                                    Liên kết ngân hàng
                                </span>
                                <KeyboardArrowRightOutlinedIcon />
                            </Link>
                            {/*<Link to="/transfer" className="account__menu-item">
                                    <span>
                                        <MoveUpIcon sx={{ fontSize: "25px" }} />
                                        Chuyển tiền
                                    </span>
                                    <KeyboardArrowRightOutlinedIcon />
                                </Link>*/}
                            <Link to="/password" className="account__menu-item">
                                <span>
                                    <img src={require("../../img/mm@3x.8985d717.png")} />
                                    Đổi mật khẩu đăng nhập
                                </span>
                                <KeyboardArrowRightOutlinedIcon />
                            </Link>
                            <Link to="/pass2" className="account__menu-item">
                                <span>
                                    <img src={require("../../img/hz@3x.15178502.png")} />
                                    Đổi mật khẩu rút tiền
                                </span>
                                <KeyboardArrowRightOutlinedIcon />
                            </Link>
                            <Link to="/invite" className="account__menu-item">
                                <span>
                                    <img src={require("../../img/jy@3x.75781f08.png")} />
                                    Mời bạn bè
                                </span>
                                <KeyboardArrowRightOutlinedIcon />
                            </Link>
                            <Link to="/promo" className="account__menu-item">
                                <span>
                                    <img src={require("../../img/yhhd@3x.5acd7219.png")} />
                                    Mã khuyến mãi
                                </span>
                                <KeyboardArrowRightOutlinedIcon />
                            </Link>
                            <div
                                className="account__menu-item"
                                onClick={() => {
                                    localStorage.removeItem("user");
                                    localStorage.removeItem("currentUser");
                                    localStorage.removeItem("profile");
                                    localStorage.removeItem("data");
                                    localStorage.removeItem("data1");
                                    var now = new Date();
                                    var time = now.getTime();
                                    var expireTime = time;
                                    now.setTime(expireTime);
                                    document.cookie = "jwt=;Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
                                    navigate("/login");
                                }}>
                                <span>
                                    <img src={require("../../img/i-cache.54077f7d.png")} />
                                    Đăng xuất
                                </span>
                                <KeyboardArrowRightOutlinedIcon />
                            </div>
                        </div>
                    </div>
                </AnimatedPage>
            )}
            <Footer profile={profile} />
        </>
    );
}
export default Profile;
